<template>
    <div v-if="started">
        <div v-if="opened" class="avia-container">
            <div class="avia-header">
                <div class="avia-header__title">
                    <span>Chat com @{{avia.name}}</span>
                </div>
                <div class="avia-header__close">
                    <button @click="close">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.9844 1.42188L8.40625 7L13.9844 12.5781L12.5781 13.9844L7 8.40625L1.42188 13.9844L0.015625 12.5781L5.59375 7L0.015625 1.42188L1.42188 0.015625L7 5.59375L12.5781 0.015625L13.9844 1.42188Z" fill="#353535"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="avia-body">
                <div class="avia-body__chat" ref="chat_messages">
                    <div class="avia-body__chat__messages" >
                        <div v-for="(item, index) in text_out_put" :key="index" class="avia-body__chat__messages__item" :class="item.class_">
                            <div class="avia-body__chat__messages__item__message" v-html="item.txt"></div>
                            <div class="name" v-if="item.class_==='avia'">@{{avia.name}}</div>
                            <div class="name" v-else>{{$t('avia.voce')}}</div>
                        </div>
                    </div>
                </div>
                <div class="avia-body__chat__input">
                    <span v-if="mic_started">
                        <svg class="mic" width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.53125 6.84375H9.65625C9.65625 7.96875 9.26042 8.95833 8.46875 9.8125C7.69792 10.6458 6.76042 11.1458 5.65625 11.3125V13.5H4.34375V11.3125C3.23958 11.1458 2.29167 10.6458 1.5 9.8125C0.729167 8.95833 0.34375 7.96875 0.34375 6.84375H1.46875C1.46875 7.82292 1.8125 8.63542 2.5 9.28125C3.20833 9.90625 4.04167 10.2188 5 10.2188C5.95833 10.2188 6.78125 9.90625 7.46875 9.28125C8.17708 8.63542 8.53125 7.82292 8.53125 6.84375ZM6.40625 8.25C6.01042 8.64583 5.54167 8.84375 5 8.84375C4.45833 8.84375 3.98958 8.64583 3.59375 8.25C3.19792 7.85417 3 7.38542 3 6.84375V2.84375C3 2.30208 3.19792 1.83333 3.59375 1.4375C3.98958 1.04167 4.45833 0.84375 5 0.84375C5.54167 0.84375 6.01042 1.04167 6.40625 1.4375C6.80208 1.83333 7 2.30208 7 2.84375V6.84375C7 7.38542 6.80208 7.85417 6.40625 8.25Z" fill="#878896" fill-opacity="0.8"/>
                        </svg>
                        /
                        <svg class="chat-icon" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 4.84375V3.5H3V4.84375H11ZM8.34375 8.84375V7.5H3V8.84375H8.34375ZM3 5.5V6.84375H11V5.5H3ZM12.3438 0.84375C12.6979 0.84375 13 0.979167 13.25 1.25C13.5208 1.5 13.6562 1.80208 13.6562 2.15625V10.1562C13.6562 10.5104 13.5208 10.8229 13.25 11.0938C13 11.3646 12.6979 11.5 12.3438 11.5H3L0.34375 14.1562V2.15625C0.34375 1.80208 0.46875 1.5 0.71875 1.25C0.989583 0.979167 1.30208 0.84375 1.65625 0.84375H12.3438Z" fill="#878896" fill-opacity="0.8"/>
                        </svg>
                        {{$t('avia.mic_dialog')}}
                    </span>
                    <span v-else>
                        <svg class="chat-icon" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 4.84375V3.5H3V4.84375H11ZM8.34375 8.84375V7.5H3V8.84375H8.34375ZM3 5.5V6.84375H11V5.5H3ZM12.3438 0.84375C12.6979 0.84375 13 0.979167 13.25 1.25C13.5208 1.5 13.6562 1.80208 13.6562 2.15625V10.1562C13.6562 10.5104 13.5208 10.8229 13.25 11.0938C13 11.3646 12.6979 11.5 12.3438 11.5H3L0.34375 14.1562V2.15625C0.34375 1.80208 0.46875 1.5 0.71875 1.25C0.989583 0.979167 1.30208 0.84375 1.65625 0.84375H12.3438Z" fill="#878896" fill-opacity="0.8"/>
                        </svg>
                        {{$t('avia.chat_dialog')}}
                    </span>
                    <div>
                        <input :disabled="!audio_ended" type="text" v-model="message" @keyup.enter="send" placeholder="Faça uma pergunta...">
                        <button  v-if="mic_started && message.length <=0" :class="{'listening': listening}" :disabled="!audio_ended" @click="startSpeech">
                            <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2969 9.01562H13.9844C13.9844 10.7031 13.3906 12.1875 12.2031 13.4688C11.0469 14.7188 9.64062 15.4688 7.98438 15.7188V19H6.01562V15.7188C4.35938 15.4688 2.9375 14.7188 1.75 13.4688C0.59375 12.1875 0.015625 10.7031 0.015625 9.01562H1.70312C1.70312 10.4844 2.21875 11.7031 3.25 12.6719C4.3125 13.6094 5.5625 14.0781 7 14.0781C8.4375 14.0781 9.67188 13.6094 10.7031 12.6719C11.7656 11.7031 12.2969 10.4844 12.2969 9.01562ZM9.10938 11.125C8.51562 11.7188 7.8125 12.0156 7 12.0156C6.1875 12.0156 5.48438 11.7188 4.89062 11.125C4.29688 10.5312 4 9.82812 4 9.01562V3.01562C4 2.20313 4.29688 1.5 4.89062 0.90625C5.48438 0.3125 6.1875 0.015625 7 0.015625C7.8125 0.015625 8.51562 0.3125 9.10938 0.90625C9.70312 1.5 10 2.20313 10 3.01562V9.01562C10 9.82812 9.70312 10.5312 9.10938 11.125Z" fill="#676768"/>
                            </svg>
                        </button>
                        <button v-else :disabled="!audio_ended" @click="send">
                            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.015625 18V11.0156L15.0156 9L0.015625 6.98438V0L21.0156 9L0.015625 18Z" fill="#676768"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
          <Audio ref="audio"/>
          <Chat ref="chat"/>
<!--          <Img ref="image"/>-->
          <Personality ref="personality"/>
          <Speech ref="speech"/>
    </div>
</template>

<script>
// import gsap from "gsap";
// import { TweenMax, ScrollTrigger, Draggable, MotionPathPlugin, Strong, Elastic } from "gsap/all";

import axios from 'axios';
import Audio from "./Audio";
import Chat from "./Chat";
import { mapGetters } from "vuex";
// import Img from "./Img";
// import Media from "./Media";
import Personality from "./Personality";
import Speech from "./Speech";

export default
{
    name: 'Controller',
    props: ['template_id','unity_id'],
    data()
    {
        return {
            count            : 0,
            dialogs          : [],
            conversation_id  : null,
            personality      : [],
            text_out_put     : [],
            avia             :{},
            started          : false,
            user_id          : null,
            message          : '',
            audio_ended      : true,
            mic_started      : false,
            listening        : false,
        }
    },
    computed:
    {
        ...mapGetters([
            'token'
        ]),
        opened(){
            return this.$parent.currentAvia == this.unity_id && this.avia.accept_input_text;
        }
    },
    components: { Audio, Chat, Personality, Speech },
    updated() {
    },
    methods:
    {
        start(){
            if(this.avia.accept_input_voice && !this.avia.accept_input_text)
                this.startSpeech();
        },
        close(){
            this.$parent.currentAvia = null;
            this.$parent.sendToUnity("AviaChatClosed",{});
        },
        audioStop(){
            this.audio_ended = true;
        },
        send()
        {
            if (this.message)
            {
                if (this.avia.accept_input_text)
                    this.audio_ended = false;
                this.addMessage(this.message);
                this.$refs['chat'].send(this.message);
                this.message = '';
            }
        },
        startSpeech()
        {
            if (this.avia.accept_input_text)
                this.audio_ended = false;
            this.listening = true;
            this.$refs['speech'].start();
        },
        initialDialog()
        {
            if (this.avia.initial_dialog)
            {
                // this.$refs['audio'].get(this.avia.initial_dialog);
                let id__ ="_" + Math.ceil(Math.random() * 99999);
                this.text_out_put.push({ "id": id__, "txt": this.avia.initial_dialog, "media": null, "class_": 'avia' });
            }
        },
        addMessage(txt, is_response = false)
        {
            let ar = [];
            if (txt)
            {
                ar =  txt.split("|||||");
                txt = ar[0];
            }
            //todo text_out_put to dialog
            let id = "_" + Math.ceil(Math.random() * 99999);
            let class_ = is_response ? 'avia' : 'user';
            // let txt__ = txt.replaceAll('\n', "<br>").replaceAll('\n', "<br>").replaceAll('\r', "<br>");
            let txt__ = txt.replaceAll('\n', "<br>").replaceAll('\n', "<br>").replaceAll('\r', "<br>");
            console.log('txt__ => ', txt__);
            this.text_out_put.push({ "id": id, "txt": txt__, "media": null, "class_": class_ });
            this.$nextTick(() => {
                if (this.$refs['chat_messages']){
                    /*global $*/
                    $(this.$refs['chat_messages']).animate({
                        scrollTop: $(this.$refs['chat_messages']).get(0).scrollHeight
                    }, 1000);
                }
            });
            if (is_response)
            {
                if (this.avia.generate_output_voice)
                {
                    this.$refs['audio'].get(txt);
                    // this.$parent.sendToUnity('SetVoice', { audioclip: '', txt: txt, unity_id:this.unity_id });
                }else{
                    this.audioStop()
                }
                /*if (this.avia.generate_background || this.force_create_img)
                    this.$refs['image'].create(txt);*/
                if (this.avia.register_dialog)
                    this.recDialog(this.text_out_put[this.text_out_put.length - 2].txt, txt);//todo rec dialog multiple avias in same page
            }else{
                this.sendToUnity("EnterBalloon",Number.parseInt(this.unity_id));
            }
        },
        recDialog(question_, answer_)
        {
            console.log('recDialog');
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access'      : 'application/json',
                // language   : this.$i18n.locale, Authorization: `Bearer ${this.token}`
            }

            let form_data = new FormData();
            form_data.append('user_id', this.user_id ? this.user_id : '');
            form_data.append('conversation_id', this.conversation_id ? this.conversation_id : '');
            form_data.append('question', question_);
            form_data.append('answer', answer_);

            axios.post(this.$url_api + "api/register-avia-dialog/" + this.avia.id, form_data, { headers: headers })
                .then((response) => this.recDialogResponse(response))
                .catch((error) => this.recDialogError(error.response));

        },
        recDialogResponse(obj)
        {
            this.conversation_id = obj.data.dialog.conversation_id;
            console.log('recDialogResponse => ', obj);
        },
        recDialogError(obj)
        {
            console.log('recDialogError => ', obj);
        },
        getAviaResponse(obj)
        {
            this.avia = obj.data.avia;
            this.user_id = obj.data.user_id;
            this.dialogs = obj.data.dialogs;
            this.conversation_id = obj.data.conversation_id;
            this.started = true;
            this.initialDialog();
            if (this.avia.accept_input_voice){
                let self = this;
                console.log("this.avia.accept_input_voice => ", this.avia.accept_input_voice);
                navigator.mediaDevices
                    .getUserMedia({
                        audio: { echoCancellation: true },
                        video: false,
                    })
                    .then((media) => {
                        if (media)
                            self.mic_started = true;
                    })
                    .catch(() => {
                        self.mic_started = false;
                    }).finally(() => {
                        console.log("this.mic_started => ", this.mic_started);
                    });
            }

            // this.initialDialog()
        },
        getAviaError(obj)
        {
            console.log('getAviaError => ', obj);

        },
        sendToUnity(event_type, unity_data)
        {
            this.$parent.sendToUnity(event_type, unity_data);
        },
    },
    mounted()
    {
        this.started = false;
        let headers = {
            "Content-Type": "application/json;charset=UTF-8",
            'Access': "application/json",
            'language': this.$i18n.locale,
            'Authorization': `Bearer ${this.token}`
        };
        axios
            .get(this.$url_api + "api/getAvia", {
                headers: headers,
                params: {
                    template_id: this.template_id,
                }
            })
            .then((response) => this.getAviaResponse(response))
            .catch((error) => this.getAviaError(error.response));
    },
    watch:{
        opened(){
            if(this.opened){
                this.$nextTick(() => {
                    if (this.$refs['chat_messages']){
                        // eslint-disable-next-line no-redeclare
                        /*global $*/
                        $(this.$refs['chat_messages']).animate({
                            scrollTop: $(this.$refs['chat_messages']).get(0).scrollHeight
                        }, 1000);
                    }
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.avia-container{
    position: fixed;
    top: var(--headers_height,0px);
    left: 0;
    width: 30%;
    height: calc(100% - var(--headers_height,0px));
    background-color: #fff;
    z-index:999;
    .avia-header{
        padding: 20px 30px 0 30px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &__title{
            font-size: 24px;
            color: #353535;
            font-weight: 700;
            line-height: 1.5;
            font-family: Exo, sans-serif;
        }
        &__close{
            position: absolute;
            top: 20px;
            right: 30px;
            button{
                width: 28px;
                height: 28px;
                border-radius: 50%;
                border:none;
                background-color: transparent;
                outline: none;
                cursor: pointer;
                svg{
                    width: 14px;
                    height: 14px;
                    path{
                        fill: #353535;
                        transition: all .3s ease-in-out;
                    }
                }
                &:hover{
                    svg{
                        path{
                            fill: #999999;
                        }
                    }
                }
            }
        }
    }
    .avia-body{
        padding: 10px 30px 20px 30px;
        width: 100%;
        height: calc(100% - 100px);
    }
    .avia-body__chat{
        display: flex;
        flex-direction: column;
        height: calc(100% - 80px);
        overflow: auto;
        margin-right: -10px;
        padding-right: 8px;
    }
    .avia-body__chat__messages__item{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;
        &.avia{
            align-items: flex-start;
            .avia-body__chat__messages__item__message{
                border-radius: 18px 18px 18px 0px;
                background: #353535;
                color: #fff;
            }
        }
        &.user{
            align-items: flex-end;
            .avia-body__chat__messages__item__message{
                border-radius: 18px 18px 0px 18px;
            }
        }
        .avia-body__chat__messages__item__message{
            height: 100%;
            padding: 24px 28px;
            background-color: #E0E1E2;
            font-size: 14px;
            color: #353535;
            font-weight: 400;
            line-height: 1.5;
            max-width: 90%;
            font-family: Exo, sans-serif;
        }
        .name{
            color: #353535;
            font-family: Exo, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .avia-body__chat__input{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        gap: 10px;
        height: 80px;
        >span{
            font-size: 14px;
            color: rgba(135,136,150,0.8);
            font-weight: 400;
            line-height: 1.5;
            font-family: Exo, sans-serif;
            .mic, .chat-icon{
                vertical-align: middle;
                height: 14px;
                width: 14px;
                path{
                    fill: rgba(135,136,150,0.8);
                }
            }
            .chat-icon{
                margin-right: 5px;
            }
        }
        >div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            input{
                width: 100%;
                height: 40px;
                border: 1px solid #878896;
                border-radius: 10px;
                padding: 0 20px;
                font-size: 16px;
                color: #676768;
                outline: none;
                &::placeholder{
                    color: #676768;
                }
                &:disabled{
                    opacity: .7;
                    cursor: not-allowed;
                }
            }
            button{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border:none;
                margin-left: 10px;
                background-color: #E0E1E2;
                outline: none;
                cursor: pointer;
                transition: all .3s ease-in-out;
                svg{
                    width: 20px;
                    height: 20px;
                    path{
                        fill: #676768;
                    }
                }
                &:hover:not(:disabled){
                    background-color: #676768;
                    svg{
                        path{
                            fill: #fff;
                        }
                    }
                }
                &:disabled{
                    opacity: .7;
                    cursor: not-allowed;
                }
                &.listening{
                    background-color: #676768;
                    opacity: 1!important;
                    animation: pulse 1s infinite;
                    svg{
                        path{
                            fill: #fff;
                        }
                    }
                }
                @keyframes pulse {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.2);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
</style>
