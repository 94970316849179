<template>
    <div id="mapa" v-if="!template_html_state">
        <div id="iframe-component" class="iframe-view" ref="iframe-view">
            <!--<iframe id="iframe" ref="iframe" :src="url_actual" frameborder="0" allowvr="yes" featurepolicy='{"vr": ["*"]}' allow="accelerometer; magnetometer; gyroscope; autoplay;" autoplay playsinline allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>-->
            <!--<iframe v-on:load="load" v-show="loaded" id="iframe" ref="iframe" :src="url" frameborder="0" allowvr="yes" allow="vr; xr; xr-spatial-tracking; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>-->
            <iframe v-if="url" id="iframe" ref="iframe" :src="url" frameborder="0" allowvr="yes" :style="styleRoot"
                    allow="display-capture *;camera *; microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;"
                    allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no"
                    @load="checkType()"/>
            <!--<button v-on:click="remove_test" style="position:absolute;left:200px;top:200px">{{ $t('iframe.remove') }}</button>-->

            <div v-if="modal_no_ticket_no_badge || modal_no_ticket || modal_no_badge" id="modal-no-badge-ticket"
                 class="modals bgAzul">
                <div class="ingresso">
                    <div class="modals__content">
                        <div class="modals__content--header alignVertical">
                            <p>{{ $t('badges.atencao') }}</p>
                            <a class="btn-0" v-on:click="redirectToMap()" ref="btn-close">
                                <img src="assets/icons/modals/x-black.svg" alt="">
                            </a>
                        </div>
                        <div class="modals__content--body flex flex-wrap text-center">
                            <div class="col-12 flex">
                                <div class="no-badge-no-ticket-subtitle">
                                    <p v-if="this.message_info" class="no-badge-ticket-message">{{
                                            this.message_info
                                        }}</p>
                                    <p v-else class="no-badge-ticket-message">{{ $t('badges.requisitos') }}</p>
                                </div>
                            </div>

                            <div v-if="badges_info && enable_badge" class="col-12 flex badges-infos">
                                <svg height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9 0.484375L0 4.51562V10.5156C0 11.8906 0.21875 13.2344 0.65625 14.5469C1.125 15.8281 1.76562 17.0156 2.57812 18.1094C3.39062 19.2031 4.34375 20.1406 5.4375 20.9219C6.53125 21.6719 7.71875 22.2031 9 22.5156C10.2812 22.2031 11.4688 21.6719 12.5625 20.9219C13.6562 20.1406 14.6094 19.2031 15.4219 18.1094C16.2344 17.0156 16.8594 15.8281 17.2969 14.5469C17.7656 13.2344 18 11.8906 18 10.5156V4.51562L9 0.484375ZM11.4844 12.1094L12.4219 15.9531L9 13.9375L5.57812 15.9531L6.51562 12.1094L3.51562 9.53125L7.45312 9.15625L9 5.5L10.5469 9.15625L14.4844 9.48438L11.4844 12.1094Z"
                                        fill="var(--buttons_color)"/>
                                </svg>
                                <div class="flex" v-for="(l, index) in badges_info" :key="index">
                                    <p class="badge-info">
                                        {{ l.name }}<span v-if="totalBadges >= 2">,</span>
                                    </p>
                                </div>
                            </div>

                            <div v-if="tickets_info && enable_ticket" class="col-12 flex tickets-infos">
                                <svg height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.9844 4.98438V3.01562H9.01562V4.98438H10.9844ZM10.9844 9.48438V7.51562H9.01562V9.48438H10.9844ZM10.9844 13.9844V12.0156H9.01562V13.9844H10.9844ZM19.9844 6.48438C19.4531 6.48438 18.9844 6.6875 18.5781 7.09375C18.2031 7.5 18.0156 7.96875 18.0156 8.5C18.0156 9.03125 18.2031 9.5 18.5781 9.90625C18.9844 10.3125 19.4531 10.5156 19.9844 10.5156V14.5C19.9844 15.0312 19.7812 15.5 19.375 15.9062C19 16.3125 18.5469 16.5156 18.0156 16.5156H1.98438C1.45312 16.5156 0.984375 16.3125 0.578125 15.9062C0.203125 15.5 0.015625 15.0312 0.015625 14.5V10.5156C0.578125 10.5156 1.04688 10.3281 1.42188 9.95312C1.79688 9.54688 1.98438 9.0625 1.98438 8.5C1.98438 7.96875 1.78125 7.5 1.375 7.09375C1 6.6875 0.546875 6.48438 0.015625 6.48438V2.5C0.015625 1.9375 0.203125 1.46875 0.578125 1.09375C0.984375 0.6875 1.45312 0.484375 1.98438 0.484375H18.0156C18.5781 0.484375 19.0469 0.6875 19.4219 1.09375C19.7969 1.46875 19.9844 1.9375 19.9844 2.5V6.48438Z"
                                        fill="var(--buttons_color)"/>
                                </svg>
                                <div class="flex" v-for="(t, index) in tickets_info" :key="index">
                                    <p class="ticket-info">
                                        {{ t.title }}<span v-if="totalTickets >= 2">,</span>
                                    </p>
                                </div>
                            </div>

                            <div v-if="label_content" class="col-12" style="text-align: center;margin-top:10px;">
                                <a href="#" v-on:click="openContent">{{ label_content }}</a>
                            </div>

                            <div class="col-12 flex flex-wrap no-badge-ticket-buttons">
                                <div class="col-12">
                                    <div v-if="(this.badge_button && this.badge_button['label'] !== '')"
                                         class="flex alinItem">
                                        <a class="button__buttonForm button__red col-12 flex"
                                           :href="!this.badge_button['url'] ? '/#/conquistar-badges' : this.badge_button['url']">
                                            <svg height="23" viewBox="0 0 18 23" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9 0.484375L0 4.51562V10.5156C0 11.8906 0.21875 13.2344 0.65625 14.5469C1.125 15.8281 1.76562 17.0156 2.57812 18.1094C3.39062 19.2031 4.34375 20.1406 5.4375 20.9219C6.53125 21.6719 7.71875 22.2031 9 22.5156C10.2812 22.2031 11.4688 21.6719 12.5625 20.9219C13.6562 20.1406 14.6094 19.2031 15.4219 18.1094C16.2344 17.0156 16.8594 15.8281 17.2969 14.5469C17.7656 13.2344 18 11.8906 18 10.5156V4.51562L9 0.484375ZM11.4844 12.1094L12.4219 15.9531L9 13.9375L5.57812 15.9531L6.51562 12.1094L3.51562 9.53125L7.45312 9.15625L9 5.5L10.5469 9.15625L14.4844 9.48438L11.4844 12.1094Z"
                                                    fill="white"/>
                                            </svg>
                                            {{ this.badge_button['label'] }}
                                        </a>
                                    </div>
                                    <div
                                        v-else-if="!modal_no_ticket && (!this.badge_button || this.badge_button['label'] == '')"
                                        class="flex alinItem">
                                        <a class="button__buttonForm button__red col-12 flex"
                                           href="/#/conquistar-badges">
                                            <svg height="23" viewBox="0 0 18 23" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9 0.484375L0 4.51562V10.5156C0 11.8906 0.21875 13.2344 0.65625 14.5469C1.125 15.8281 1.76562 17.0156 2.57812 18.1094C3.39062 19.2031 4.34375 20.1406 5.4375 20.9219C6.53125 21.6719 7.71875 22.2031 9 22.5156C10.2812 22.2031 11.4688 21.6719 12.5625 20.9219C13.6562 20.1406 14.6094 19.2031 15.4219 18.1094C16.2344 17.0156 16.8594 15.8281 17.2969 14.5469C17.7656 13.2344 18 11.8906 18 10.5156V4.51562L9 0.484375ZM11.4844 12.1094L12.4219 15.9531L9 13.9375L5.57812 15.9531L6.51562 12.1094L3.51562 9.53125L7.45312 9.15625L9 5.5L10.5469 9.15625L14.4844 9.48438L11.4844 12.1094Z"
                                                    fill="white"/>
                                            </svg>
                                            {{ $t('badges.conquistarBadge') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div v-if="modal_no_ticket || modal_no_ticket_no_badge" class="flex alinItem">
                                        <a class="button__buttonForm button__red col-12 flex" v-on:click="goTickets()">
                                            <svg height="17" viewBox="0 0 20 17" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.9844 4.98438V3.01562H9.01562V4.98438H10.9844ZM10.9844 9.48438V7.51562H9.01562V9.48438H10.9844ZM10.9844 13.9844V12.0156H9.01562V13.9844H10.9844ZM19.9844 6.48438C19.4531 6.48438 18.9844 6.6875 18.5781 7.09375C18.2031 7.5 18.0156 7.96875 18.0156 8.5C18.0156 9.03125 18.2031 9.5 18.5781 9.90625C18.9844 10.3125 19.4531 10.5156 19.9844 10.5156V14.5C19.9844 15.0312 19.7812 15.5 19.375 15.9062C19 16.3125 18.5469 16.5156 18.0156 16.5156H1.98438C1.45312 16.5156 0.984375 16.3125 0.578125 15.9062C0.203125 15.5 0.015625 15.0312 0.015625 14.5V10.5156C0.578125 10.5156 1.04688 10.3281 1.42188 9.95312C1.79688 9.54688 1.98438 9.0625 1.98438 8.5C1.98438 7.96875 1.78125 7.5 1.375 7.09375C1 6.6875 0.546875 6.48438 0.015625 6.48438V2.5C0.015625 1.9375 0.203125 1.46875 0.578125 1.09375C0.984375 0.6875 1.45312 0.484375 1.98438 0.484375H18.0156C18.5781 0.484375 19.0469 0.6875 19.4219 1.09375C19.7969 1.46875 19.9844 1.9375 19.9844 2.5V6.48438Z"
                                                    fill="white"/>
                                            </svg>
                                            {{ $t('badges.irBilheteria') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="modal_content_control" id="modal-content-control" class="modals bgAzul"
                 :class="this.modalFs ? 'fullscren-lightbox' : ''">
                <div class="content_control" style="position: relative;">
                    <div class="modals__content alignVertical">
                        <div class="modals__content--header alignVertical">
                            <a v-if="!modalFs" href="#" v-on:click="modalFs = true">
                                <svg
                                    style="position: absolute;width: 20px;height: 20px;right: 60px;top: 50%;transform: translateY(-50%);"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"
                                        fill="#fff"/>
                                </svg>
                            </a>
                            <a v-if="!modalFs" class="btn-0" v-on:click="modal_content_control = false" ref="btn-close">
                                <img src="assets/icons/modals/x.svg" alt="">
                            </a>
                            <a v-if="modalFs" href="#" v-on:click="modalFs = false">
                                <svg
                                    style="position: absolute;width: 20px;height: 20px;right: 15px;top: 50%;transform: translateY(-50%);"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"
                                        fill="#fff"/>
                                </svg>
                            </a>
                        </div>
                        <div class="modals__content--body alignVertical">
                            <div class="col-12 flex div-fs-iframe">
                                <iframe :src="url_content" frameborder="0"></iframe>
                            </div>

                        </div>
                        <div class="modals__content--footer flex"></div>
                    </div>
                </div>
            </div>

            <!-- TELA DE FULL SCREEN -->
            <!--<div v-if="modalFs" class="modals bgBlack"
                style="position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 99999999999999;">
                <div class="modals__content--header alignVertical">
                    <a href="#" class="close" data-response="0" v-on:click="modalFs = false">
                        <svg style="position: absolute;width: 20px;height: 20px;right: 20px;top: 50%;transform: translateY(-50%);"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"
                                fill="#fff" />
                        </svg>
                    </a>
                </div>
                <iframe :src="url_content" style="height:90%;"></iframe>
            </div>-->
            <!-- FIM TELA DE FULL SCREEN -->

            <div id="btn-back" ref="btn-back" @click="back">
                <img :src="this.$url + 'assets/img/backbutton-redux.png'" alt="Image" width="48">
            </div>
            <Transcription v-if="startTranscription" />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from 'axios';
import Transcription from "@/components/transcription/Transcription.vue";

export default {
    name: 'Iframe',
    components: {Transcription},
    props: ['sessionName'],
    computed: {
        
        ...mapGetters([
            'id', 'token', 'logged', 'name', 'url_actual', 'nickname', 'switch', 'UserTickets', 'ticket_actual', 'sessions', 'checkMobile', 'checkIOS',
            'internet_minimum_speed', 'internet_test_url', 'internet_time_cache', 'use_map','current_session', 
            'design', 'UserBadges', 'UserTickets', 

            'lock_favicon', 'avatar_icon', 'avatar_map',

            'avatar_first_session', 
            'last_local', 'sessions', 'AllBadges', 'maps', 'event_map_user_progress'
        ]),
        ...mapGetters({
            template_html_state: 'TemplateHTML/getHTMLTemplateLoaded'
        }),
        styleRoot() {
            let style = {};
            if (!this.checkMobile && this.$app.currentHash && this.$app.currentHash?.modal_position && this.$app.currentHash?.modal_position != 'default') {
                let modalWidth = this.totalWidth;
                switch (this.$app.currentHash.modal_type) {
                    case '2_3':
                    case '1_3':
                    case '1_2':
                        modalWidth = this.totalWidth * (this.$app.currentHash.modal_type.split('_')[0] / this.$app.currentHash.modal_type.split('_')[1]);
                        break;
                }
                let modalMaxWidth = this.$app.currentHash?.modal_width_max;
                if (modalMaxWidth > 0) {
                    modalMaxWidth = modalMaxWidth > this.totalWidth ? this.totalWidth : modalMaxWidth;
                    if (modalMaxWidth < modalWidth)
                        modalWidth = modalMaxWidth;
                }
                let modalMinWidth = this.$app.currentHash?.modal_width_min;
                if (modalMinWidth > 0) {
                    modalMinWidth = modalMinWidth > this.totalWidth ? this.totalWidth : modalMinWidth;
                    if (modalMinWidth > modalWidth)
                        modalWidth = modalMinWidth;
                }
                style['width'] = this.totalWidth - modalWidth + "px";
                if (this.$app.currentHash?.modal_position === 'left') {
                    style['margin-left'] = modalWidth + "px";
                }
            }
            return style;
        },
        startTranscription() {
            return this.$app.chat && (this.current_session.template_type ==="unity" && this.current_session?.agora_io?.enable) && this.current_session.transcription;
        }
    },
    data() {
        return {
            //loaded: false,
            url: null,
            url_temp: null,
            modal_no_ticket: false,
            modal_no_badge: false,
            modal_no_ticket_no_badge: false,
            badge_button: null,
            badges_info: null,
            tickets_info: null,
            message_info: null,
            totalBadges: 0,
            totalTickets: 0,
            enable_ticket: false,
            enable_badge: false,
            label_content: null,
            url_content: null,
            modal_content_control: false,
            modalFs: false,
            is_minigame: false,
            totalWidth: 0,
        }
    },
    methods: {
        ...mapActions([
            'update_url_actual', 'update_current_session', 'update_id_form_actual','update_mini_game'
        ]),
        /*
        load: function(){
            this.loaded = true;
        },
        */
        remove_test() {
            this.$refs['iframe'].src = '';
        },
        enterLocal(local, chat_room = null) {
            this.$app.wait(true);
            let headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access': 'application/json',
                language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
            };
            axios.post(this.$url_api + 'api/credit/enter_ambient', {
                'local': local.name,
                'session_id': local.room_id,
                'chat_room': chat_room
            }, {headers: headers})
                .then(response => this.enterLocalResponse(response))
                .catch((error) => this.enterLocalError(error.response));
        },
        changeUrl(obj) {
            const root = this;
            const session = root.sessions.find(obj => {

                if (obj.url === root.url_temp) {
                    root.$app.sessionHandler(obj);
                }

                return obj.url === root.url_temp;
            });
            let password
            if (this.use_map == 3 && this.$route.name == 'Map') {
                password = this.$app.toDecrypt(this.$app.getCookie('map'))
            } else {
                password = this.$app.toDecrypt(this.$app.getCookie(`${this.switch.Nome_Aba}-${session.name}`))
            }
            if (this.url_temp.indexOf("?") !== -1)
                this.url_temp += `&chat_token=${this.token}`;
            else
                this.url_temp += `?chat_token=${this.token}`;
            if (password.length > 0) {
                this.url_temp += `&password=${password}`;
            }
            if (this.url_temp.indexOf("?") !== -1)
                this.url_temp += `&lang=${this.$i18n.locale}`;
            else
                this.url_temp += `?lang=${this.$i18n.locale}`;
            this.url = this.url_temp;

            try
            {
                if (obj.data.template.typeFile == '360') {
                    setTimeout(()=> {
                        let krpano_communication = JSON.stringify({
                            design: root.design,
                            user_badges: root.UserBadges,
                            user_tickets: root.UserTickets,
                            lock_favicon: root.design.lock_favicon,
                            avatar_icon: root.design.avatar_icon,
                            avatar_map: root.avatar_map,
                            avatar_first_session: root.avatar_first_session,
                            last_local: root.last_local,
                            sessions: root.sessions,
                            all_badges: root.AllBadges,
                            event_map_user_progress: root.event_map_user_progress,
                            map_user_progress: obj.data.template.map_user_progress
                        });
                        document.getElementById('iframe').contentWindow.postMessage({ message_type: "external",  data: "front_communication_iframe", params: krpano_communication }, "*");
                    }, 1000);
                }
            }
            catch(e) { console.log(e); }
        },
        enterLocalResponse(obj) {
            
            obj;
            this.changeUrl(obj);
            this.$app.wait(false);
        },
        enterLocalError(obj) {
            obj;
            try
            {
                if (obj.status === 401 && obj.data.message.modal === 'no_ticket') {
                    this.modal_no_ticket = true;
                    this.tickets_info = obj.data.message.ticket_info;
                    this.totalTickets = this.tickets_info.length;
                    this.message_info = (obj.data.message.badge_button) ? obj.data.message.badge_button.info : '';
                    this.enable_ticket = (obj.data.message.enable_infos) ? obj.data.message.enable_infos.ticket : '';
                    this.label_content = obj.data.message.content_control ? obj.data.message.content_control.content_label : '';
                    this.url_content = obj.data.message.content_control ? obj.data.message.content_control.content_url : '';
                } else if (obj.status === 401 && obj.data.message.modal === 'no_badge') {
                    this.modal_no_badge = true;
                    this.badge_button = obj.data.message.badge_button;
                    this.badges_info = obj.data.message.badge_info;
                    this.totalBadges = this.badges_info.length;
                    this.message_info = (obj.data.message.badge_button) ? obj.data.message.badge_button.info : '';
                    this.enable_badge = (obj.data.message.enable_infos) ? obj.data.message.enable_infos.badge : '';
                    this.label_content = obj.data.message.content_control ? obj.data.message.content_control.content_label : '';
                    this.url_content = obj.data.message.content_control ? obj.data.message.content_control.content_url : '';
                } else if (obj.status === 401 && obj.data.message.modal === 'no_ticket_no_badge') {
                    this.modal_no_ticket_no_badge = true;
                    this.badge_button = obj.data.message.badge_button;
                    this.badges_info = obj.data.message.badge_info;
                    this.tickets_info = obj.data.message.ticket_info;
                    this.totalBadges = this.badges_info.length;
                    this.totalTickets = this.tickets_info.length;
                    this.message_info = (obj.data.message.badge_button) ? obj.data.message.badge_button.info : '';
                    this.enable_badge = (obj.data.message.enable_infos) ? obj.data.message.enable_infos.badge : '';
                    this.enable_ticket = (obj.data.message.enable_infos) ? obj.data.message.enable_infos.ticket : '';
                    this.label_content = obj.data.message.content_control ? obj.data.message.content_control.content_label : '';
                    this.url_content = obj.data.message.content_control ? obj.data.message.content_control.content_url : '';
                } else {
                    this.changeUrl();
                }
            }
            catch(e) { console.log(e); }
            this.$app.wait(false);
        },
        //----- \load-friends\ -----//
        selectAnswer() {
            document.getElementsByName('answer').forEach(e => {
                const parent = e.parentElement.parentElement;
                parent.className = parent.className.replace(' active', '');
                if (e.checked) {
                    parent.className += ' active';
                    this.selectedAnswer = e.value;
                }
            });
        },
        iframeURL() {
            // console.log('### iframeURL');
            const sessionName = this.sessionName;
            const sessions = JSON.parse(JSON.stringify(this.sessions));
            let root_ = this;
            // console.log('### Iframe.vue modificado 01-08-23 00:48');
            const session = sessions.find(obj => {

                // console.log('### obj.scene_name, sessionName => ', obj.scene_name, sessionName);
                if (obj.name === sessionName) {
                    // if (obj.scene_name === sessionName) {
                    root_.$app.sessionHandler(obj);
                }
                return obj.name === sessionName;
                // return obj.scene_name === sessionName;
            });
            if (session.lms) {
                root_.$store.dispatch('LMS/update_lms', session.lms);
                root_.$router.push({path: `/lms/${session.lms.slug}`});
            }
            // console.log('### Iframe.vue modificado 01-08-23 00:48 session => ', session);
            if ((session !== undefined) && (session !== null)) {

                if (this.internet_minimum_speed > 0 && this.internet_test_url && session.lite_session) {
                    root_.$app.wait(true);
                    this.internetSpeed().then((internet_speed) => {
                        root_.$app.wait(false);
                        if (internet_speed >= this.internet_minimum_speed)
                            this.goSession(session);
                        else
                            throw 'internet speed';
                    }).catch(() => {
                        root_.$router.push({ name: 'Iframe', params:{sessionName: session.lite_session,eventName:localStorage.getItem("eventmap")} });
                    });
                } else {
                    this.goSession(session);
                }
            }
        },
        internetSpeed() {
            return new Promise((resolve, reject) => {
                let internet_speed = localStorage.getItem('internet_speed')
                if (internet_speed) {
                    internet_speed = JSON.parse(internet_speed);
                    let d = (Date.now() - internet_speed.startTime) / 1000 / 60;
                    if (internet_speed.startTime && d < this.internet_time_cache) {
                        resolve(parseFloat(internet_speed.speed));
                        return;
                    }
                }
                const startTime = Date.now();
                let imageAddr = `${this.internet_test_url}?nnn=${startTime}`;
                let t = setTimeout(() => {
                    localStorage.removeItem('internet_speed');
                    reject('timeout');
                }, 10000);
                fetch(imageAddr)
                    .then(async res => {
                        let blob = await res.blob();
                        return {blob, headers: res.headers}
                    })
                    .then(({blob, headers}) => {
                        blob;
                        const endTime = Date.now();
                        const contentLength = headers.get('content-length');
                        const duration = endTime - startTime;
                        if (contentLength) {
                            const fileSize = parseInt(contentLength, 10);
                            const speedBps = ((fileSize * 8) / (duration / 1000)).toFixed(2);
                            const speedKbps = (speedBps / 1024).toFixed(2);
                            const speedMbps = (speedKbps / 1024).toFixed(2);
                            clearTimeout(t);
                            localStorage.setItem('internet_speed', JSON.stringify({speed: speedMbps, startTime}));
                            resolve(parseFloat(speedMbps));
                        } else {
                            reject('No content-length');
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        goSession(session) {
            this.enterLocal(session);
            //this.enterLocal(session.name);
            this.$app.analytics(session.name);

            this.update_current_session(session);
            if (session.quiz !== 0) {
                this.update_id_form_actual(session.form_id);
            }
            if (session.isForm || session.isBoardGame) {
                this.$app.modal_Wizard = false; // dont show wizard form forms and trivias iframes
            }
            const sessionURL = session.url;
            this.url_temp = sessionURL;
            this.$route.meta.title = session.name;
        },
        redirectToMap() {
            this.$app.link('/map');
        },


        back() {
            console.log('back');
            this.$app.link('Map');
        },
        miniGameResize() {
            if (this.is_minigame) {
                var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                var isPortrait = viewportHeight > viewportWidth;

                var newViewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                var newViewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                var hasOrientationChanged = (newViewportHeight > newViewportWidth) !== isPortrait;
                var addressbarHeight = 130;


                let final_height = newViewportHeight;

                if (!hasOrientationChanged && (newViewportHeight != viewportHeight)) {
                    addressbarHeight = Math.abs(newViewportHeight - viewportHeight);
                    if (newViewportHeight < viewportHeight) {
                        // Android Chrome address bar has appeared
                        final_height = (viewportHeight - addressbarHeight);
                    } else {
                        // Android Chrome address bar has disappeared
                        final_height = (viewportHeight + addressbarHeight);
                    }
                } else if (hasOrientationChanged) {
                    // Orientation change
                }

                viewportHeight = final_height;
                viewportWidth = newViewportWidth;
                console.log('viewportHeight, viewportWidth => ', viewportHeight, final_height, viewportWidth);
                isPortrait = viewportHeight > viewportWidth;

                this.$refs["iframe-view"].style.marginTop = '0px';
                this.$refs["iframe-view"].style.width = viewportWidth + 'px';
                this.$refs["iframe-view"].style.height = viewportHeight + 'px';
            }
        },
        isMiniGame() {
            // if (!this.checkIOS)

            // if (this.checkMobile)
            // {

            console.log('aqui dentro');
            this.update_mini_game(true);
            this.$refs["iframe-view"].style.marginTop = '0 !important';

            if (!this.checkIOS) {
                try {
                    document.getElementById('iframe').contentWindow.setFullScreen();
                } catch (e) {
                    document.getElementById('iframe').contentWindow.postMessage({type: 'setFullScreen'}, '*')
                }
            }
            this.$refs['btn-back'].style.display = 'block';


            // this.$app.$refs['header-main'].style.display = 'none';
            // this.$refs["iframe-view"].style.position = 'absolute';
            // this.$refs["iframe-view"].style.height = 'calc(100% - 40px)';
            // this.$refs["iframe-view"].style.width = '100%';
            // this.$refs["iframe-view"].style.left = '0';
            // this.$refs["iframe-view"].style.marginTop = '0 !important';

            // this.$refs["iframe-view"].style.minHeight =  '-webkit-fill-available';
            // this.$refs["iframe-view"].style.bottom = 'calc(10px + (100vh - 100%))';
            // this.$refs["iframe-view"].style.bottom = 'calc(100vh - 100%)';
            // this.$refs["iframe-view"].style.position = 'fixed';
            // this.$refs["iframe-view"].style.height = '100vh';
            // this.$refs["iframe-view"].style.width = '100%';

            // this.$refs["iframe"].style.position = 'absolute';
            // this.$refs["iframe"].style.top = '0 !important';
            // this.$refs["iframe"].style.height = '100%';

            //document.getElementById('iframe').contentWindow.getElementById('fullscreen-btn').style.display = 'none';
            // }

            this.onResize();
        },
        Appcheck() {
            //quando é minigame
            this.is_minigame = true;
            this.isMiniGame();
        },
        checkType() {
            console.log('here => ', document.getElementById('iframe').contentWindow);
            //document.getElementById('iframe').contentWindow.getElementById('fullscreen-btn').style.display = 'none';
        },


        onResize() {
            this.totalWidth = window.innerWidth;
            if (!this.$refs["iframe-view"]) return;
            if (window.innerWidth > 1000 && this.$app.$refs['copyright_footer']) {
                this.$refs["iframe-view"].style.height = `calc(100vh - var(--headers_height) - ${this.$app.$refs['copyright_footer'].clientHeight}px)`;
                this.$app.$refs["copyright_footer"].style.position = `fixed`;
            } else {
                this.$refs["iframe-view"].style = "";
            }

            this.miniGameResize();
        },
        goTickets() {
            //this.$app.link('Profile');
            //this.$router.push({name: 'Profile', params: {tab: 'finance'}});
            window.location.href = '#/profile?tab=buy'
        },
        openContent() {
            if (this.url_content) {
                this.modal_content_control = true;
            }
        },
        openModalFS() {
            this.modalFs = true;
        }
    },
    mounted() {
        // window.addEventListener('touchstart', {});

        // if (this.$route.name != "Museum" && this.$route.name!="LockerRoom" && this.$route.name != "Store"){
        //     this.$app.iframecomponent = true;
        // }
        /*
        if (!this.logged)
        {
            this.link("Home");
            this.$app.modal_login_ = true;
        }
        */
        this.totalWidth = window.innerWidth;

        document.querySelector('html').classList.add('iframehtml');
        this.iframeURL();
        this.$nextTick(() => {
            this.onResize()
            window.addEventListener('resize', this.onResize);
        })
        window.addEventListener("message", (e) => {
            if (e.data.data == 'block' || e.data.data == 'ask') {
                this.$app.sessionAccess(e.data.data)
            }

        })
    },
    beforeDestroy() {
        this.update_mini_game(false);
        document.querySelector('html').classList.remove('iframehtml');
        // document.getElementById('iframe').remove();
        /*
        try {
            window.removeEventListener('touchstart', {});
        }
        catch(e) {
            //
        }*/
        this.$app.iframecomponent = false;
        this.update_current_session({});
        try {
            this.$refs['iframe'].src = null;
        } catch (e) {
            //
        }
        window.removeEventListener('resize', this.onResize);
    },
}
</script>
<style scoped>
.iframe-view {
    height: calc(100vh - var(--headers_height));
    margin-top: var(--headers_height);
    position: relative;
}

iframe {
    display: block;
    height: 100%;
    width: 100%;
}

#btn-back {
    display: none;
    position: fixed;
    left: 10px;
    top: 10px;
}

@media screen and (max-width: 1000px) {
    .iframe-view {
        position: absolute;
        height: calc(100% - var(--headers_height));
        width: 100%;
        margin-top: 0;
        top: var(--headers_height);
    }
}
</style>