<template>
    <div id="mapa" ref="mapa">
        <section v-if="this.use_map == 1" class="menuMap">
            <div  id="aerial-map" ref="aerial-map">
            <section class="container-iframe">
            <iframe id="iframe" ref="iframe" :src="map_final" frameborder="0" width="100%" height="100%" allowvr="yes" allow="camera *;microphone *;vr *; xr *; xr-spatial-tracking *; accelerometer; magnetometer; gyroscope; autoplay;" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" scrolling="no"></iframe>
            </section>
            <section class="menu-map">
            <div class="map-content">
                <div class="container-event-title">
                <p class="title-event">{{ $t('map.avantPremiere') }}</p>
                </div>
                <img class="event-image" src="assets/icons/myAccount/mock-event-image.png" alt="imagem do evento"/> 
                <div class="container-description">
                <p class="description-event">{{ $t('map.loremIpsumDolor') }}</p>
                <button class="button-enter">{{ $t('map.entrar') }}</button>
                </div>
            </div>
            <footer class="container-footer">
                <img src="assets/images/footer.svg" alt="background do footer (menu mapa)">
            </footer>
            </section> 
        </div>
        </section>
        <MapMosaico v-else-if="this.use_map == 2" />
        <MapSession v-else-if="!this.switch.validate_user" />
        <ModalOptins v-if="this.switch.validate_user" ref="modalOptins" />
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import MapMosaico from "@/components/pages/MapMosaico";
import MapSession from "@/components/pages/MapSession";
import ModalOptins from "@/components/Modals/ModalOptins.vue";

export default {
    name: 'Map',
    components: {
      ModalOptins,
      // MapButton, 
      MapMosaico,
      MapSession,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            swiper_map: null,
            swiper_wizard: null,
            img_map: {
                filter: 'brightness(1)'
            },
            sidebar: {
                on: false,
                title: "",
                description: "",
                thumb: "",
                url: "",
            },
            // sidebar: {
            //     on: false,
            //     title: "Ambiente 1",
            //     description: "Lorem ipsum dolor sit amet",
            //     thumb: "http://placehold.it/300",
            //     url: "www.google.com",
            // },
            // test : false,
        }
    },
    computed: {
          ...mapGetters([
              'sessions', 'switch', 'age','maps',"checkIOS", "checkMobile","checkSafari","use_map"
          ]),
        map_final: function () {
            if (this.checkIOS && this.maps.ios) {
                return this.maps.ios;
            } else if ((this.checkIOS || this.checkMobile) && this.maps.android) {
                return this.maps.android;
            } else if ((this.checkSafari) && this.maps.safari) {
                return this.maps.safari;
            }
            return this.maps.default;
        }
    },
    methods: {
      openSidebar(name) {
        this.$app.wait(true);
        // this.sidebar.description = "";
        // this.sidebar.thumb = "";
        
        if (this.sessions) {
            let session = this.sessions.filter(_ => _.name === name)[0];
            this.sidebar.description = session ? session.description : '';
            this.sidebar.thumb = session ? session.tumbnail : '';
        }
      this.sidebar.on = true;
      switch (name) {
          case 'Fan Zone':
              this.sidebar.title = this.$i18n.t('map.fanZone');
              this.sidebar.url = "";
              break;
          case 'Feel Zone':
              this.sidebar.title = this.$i18n.t('map.feelZone');
              this.sidebar.url = "FeelZone";
              break;
          case 'Play Zone':
              this.sidebar.title = this.$i18n.t('map.playZone');
              this.sidebar.url = "PlayZone";
              break;
          case"NBA THEATER":
              this.sidebar.title = this.$i18n.t('map.auditorio');
              this.sidebar.url = "Theater";
              break;
          case"NBA STADIUM":
              this.sidebar.title = this.$i18n.t('map.arena');
              this.sidebar.url = "Stadium";
              break;
          case"NBA BAR":
              this.sidebar.title = this.$i18n.t('map.bar');
              this.sidebar.url = "Bar";
              break;
          case"NBA MUSEUM":
              this.sidebar.title = this.$i18n.t('map.museu');
              this.sidebar.url = "Museum";
              break;
          case"NBA STORE":
              this.sidebar.title = this.$i18n.t('map.lojaNBA');
              this.sidebar.url = "Store";
              break;
          case"Locker Room Curiosities":
              this.sidebar.title = this.$i18n.t('map.lockerRoom');
              this.sidebar.url = "LockerRoom";
              break;
          case"AVATAR SHOP":
              this.sidebar.title = this.$i18n.t('map.avatarShop');
              this.sidebar.url = "City";
              break;
      }
      this.$app.wait(false);
      },
        onResize(){
            if (!this.$refs["mapa"])return;
            if(window.innerWidth>1000  && this.$app.$refs['copyright_footer']) {
                this.$refs["mapa"].style.height = `calc(100vh - ${this.$app.$refs['copyright_footer'].clientHeight}px)`;
                this.$app.$refs["copyright_footer"].style.position = `fixed`;
            }else {
                this.$refs["mapa"].style = "";
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.onResize()
            window.addEventListener('resize', this.onResize);
        })

        document.body.classList.remove('lms');
    },
    created()
    {
        /*
        console.log('LMS Map => ', this.lms);
        */
        // this.$app.LMSHandler(this.lms, 'map', '', '');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
}
</script>

<style scoped>
#mapa
{
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 1000px) {
    #mapa
    {
        height: 100%;
        /*
        height: calc(100% - 45px);
        margin-top: 45px;
        */
    }
}
</style>
