<template>
    <div>
        <div ref="modal" class="ui suimodal tiny" :class="{isMobile}" :style="cssVars">
            <div class="header">
                <div class="ui grid">
                    <div class="eight wide column">
                        <h3>Slides</h3>
                    </div>
                    <div class="eight wide column">
                        <i class="close icon" @click="close"></i>
                    </div>
                    <div class="sixteen wide column">
                        <button class="ui button primary basic fluid" @click="restart">
                            <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 2.84375C6.22917 2.84375 6.91667 2.97917 7.5625 3.25C8.20833 3.52083 8.77083 3.90625 9.25 4.40625C9.75 4.88542 10.1354 5.44792 10.4062 6.09375C10.6979 6.73958 10.8438 7.42708 10.8438 8.15625C10.8438 9.07292 10.6354 9.91667 10.2188 10.6875C9.80208 11.4375 9.23958 12.0625 8.53125 12.5625C7.82292 13.0417 7.03125 13.3438 6.15625 13.4688V12.0938C6.80208 11.9896 7.375 11.7604 7.875 11.4062C8.375 11.0312 8.77083 10.5625 9.0625 10C9.35417 9.4375 9.5 8.82292 9.5 8.15625C9.5 7.42708 9.3125 6.76042 8.9375 6.15625C8.58333 5.55208 8.10417 5.07292 7.5 4.71875C6.89583 4.34375 6.22917 4.15625 5.5 4.15625V6.15625L2.84375 3.5L5.5 0.84375V2.84375ZM1.5 8.15625C1.5 8.82292 1.64583 9.4375 1.9375 10C2.22917 10.5625 2.625 11.0312 3.125 11.4062C3.625 11.7604 4.19792 11.9896 4.84375 12.0938V13.4688C3.96875 13.3438 3.17708 13.0417 2.46875 12.5625C1.76042 12.0625 1.19792 11.4375 0.78125 10.6875C0.364583 9.91667 0.15625 9.07292 0.15625 8.15625C0.15625 7.42708 0.291667 6.73958 0.5625 6.09375C0.854167 5.44792 1.23958 4.88542 1.71875 4.40625L2.6875 5.34375C2.3125 5.69792 2.02083 6.125 1.8125 6.625C1.60417 7.10417 1.5 7.61458 1.5 8.15625Z" fill="white"/>
                            </svg>
                            Reiniciar apresentação
                        </button>
                    </div>
                </div>
            </div>
            <div class="content scrolling">
                <div class="ui one column grid">
                    <div v-for="(slide,index) in slides" :key="index" class="column slide" :class="{active:index===currentSlide}" @click="goTo(index)">
                        {{ slide.content.title }}
                        <div v-if="slide.file_url" class="ui image">
                            <img :src="slide.file_url" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <div class="ui grid">
                    <div class="five wide column">
                        <button v-if="currentSlide > 0" class="ui button primary fluid" @click="goBack">
                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.28125 1.4375L2.21875 4.5L5.28125 7.5625L4.34375 8.5L0.34375 4.5L4.34375 0.5L5.28125 1.4375Z" fill="white"/>
                            </svg>
                            Anterior
                        </button>
                    </div>
                    <div class="six wide column">
                        <h3>Slide {{ currentSlide + 1 }} of {{ slides.length }}</h3>
                    </div>
                    <div class="five wide column">
                        <button v-if="currentSlide < slides.length - 1" class="ui button primary fluid" @click="goNext">
                            Próximo
                            <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.15625 0.5L5.15625 4.5L1.15625 8.5L0.21875 7.5625L3.28125 4.5L0.21875 1.4375L1.15625 0.5Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import axios from 'axios';
    import { mapGetters} from "vuex";
    
    export default {
        name: 'ModeratorSlides',
        computed: {
            ...mapGetters([
                'logged', 'token', 'id', 'nickname', 'sessions', 'groups','design'
            ]),
            isMobile(){
                return this.$store.getters.isMobile && this.$app.windowWidth < 1000;
            },
            cssVars(){
                let fontColor = this.design?.color?.fonts_color || this.$app.getCookie("design_color_fonts_color") || "#000000";
                fontColor = this.$app.hexToRgb(fontColor);
                return {
                    '--fonts_color_50': `rgba(${fontColor.r},${fontColor.g},${fontColor.b},0.5)`,
                }
            }
        },
        props: [],
        data() {
            return {
                room_id: "Deloitte_Vision",
                currentSlide: 0,
                slides: [],
            }
        },
        methods: {
            goBack() {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.room_id, subtype:'previous_event'}).then(() => {
                    this.currentSlide--
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            goNext() {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.room_id, subtype:'next_event'}).then(() => {
                    this.currentSlide++
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            restart() {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.room_id, subtype:'restart_event'}).then(() => {
                    this.currentSlide = 0
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            goTo(position) {
                this.$app.wait(true);
                this.sendBroadcast({room_id:this.room_id, subtype:'go_to_event', data:position}).then(() => {
                    this.currentSlide = position
                }).finally(()=>{
                    this.$app.wait(false);
                })
            },
            getSlides(){
                return new Promise((resolve, reject) => {
                    axios.get(this.$url_api + 'api/slides/xr', {
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Access': 'application/json',
                            language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                        }
                    }).then((response) => {
                        this.slides = response.data.data.slides;
                        this.room_id = response.data.data.room_id;
                        resolve(response.data.data)
                    }).catch((error) => {
                        console.log(error)
                        reject(error)
                    });
                })
            },
            sendBroadcast(data) {
                return new Promise((resolve, reject) => {
                    axios.post(this.$url_api + 'api/mod/broadcast', data,{
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Access': 'application/json',
                            language: this.$i18n.locale, Authorization: `Bearer ${this.token}`
                        }
                    }).then((response) => {
                        resolve(response.data)
                    }).catch((error) => {
                        reject(error)
                    })
                })
            },
            close() {
                window.$(this.$refs.modal).suimodal('hide').suimodal('destroy')
            },
        },
        created() {
        },
        mounted() {
        //     open sui modal
            this.$app.wait(true);
            this.getSlides().then(() => {
                this.$app.wait(false);
                window.$(this.$refs.modal).suimodal({
                    closable: false,
                    onApprove: () => {
                        return false
                    },
                    onHidden: () => {
                        this.$app.moderatorComponent = null;
                    }
                }).suimodal('show');
            }).catch(() => {
                this.$app.wait(false);
            });
        },
    }
</script>

<style scoped lang="scss">
.ui.suimodal {
    background-color: var(--headers_color_logged);
    color: var(--fonts_color);
    .header,.content,.actions{
        background-color: transparent;
        color: var(--fonts_color);
    }
    .close.icon{
        float: right;
    }
    .actions h3{
        margin: 0;
        font-size: 1.25rem;
        text-align: center;
    }
    &.isMobile {
        position: fixed;
        top: 0 !important;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 0;
        z-index: 1000;

        .content {
            height: calc(80vh - 4rem);
            max-height: calc(80vh - 4rem);
            overflow-y: auto;
        }
    }
    .slide {
        margin-bottom: 1rem;
        cursor: pointer;
        color: var(--fonts_color_50);
        &.active {
            background-color: rgba(240, 240, 240, 0.2);
            color: var(--fonts_color);
            font-weight: bold;
        }
        .ui.image {
            height: 200px;
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        .button {
            margin: 0;
        }
    }
    .ui.button {
        margin: 0;
        border-radius: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        svg {
            height: 1rem;
            width: 1rem;
            path {
                fill: var(--fonts_color);
            }
        }
        &.primary {
            background-color: var(--buttons_color);
            color: var(--fonts_color);
        }
        &.basic {
            &.primary {
                background-color: transparent;
                box-shadow: 0 0 0 1px var(--buttons_color) inset;
                color: var(--fonts_color);
            }
        }
    }
}
</style>