import { render, staticRenderFns } from "./LmsCourse.vue?vue&type=template&id=08890994&scoped=true"
import script from "./LmsCourse.vue?vue&type=script&lang=js"
export * from "./LmsCourse.vue?vue&type=script&lang=js"
import style0 from "./LmsCourse.vue?vue&type=style&index=0&id=08890994&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08890994",
  null
  
)

export default component.exports