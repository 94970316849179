<template>
    <div id="home" ref="home" v-if="!template_html_loaded">
        <div class="home">
            <div class="contentBanner">
                <div class="swiper-container home__banner">
                    <div class="swiper-wrapper">
                        <div :key="index" v-for="(splash, index) in total_splashs"
                              class="swiper-slide alignVertical carousel"
                             >
                                <div v-if="countdownEnable" class="countdown-event alignVertical flex" :style="{ backgroundImage: countdownImage ? 'url(' + countdownImage + ')' : 'none' }">
                                    <div v-if="countdownTitle" class="col-12 countdown-title">
                                        <h4>{{ countdownTitle }}</h4>
                                    </div>

                                    <div class="col-3 countdown-infos">
                                        <div class="countdown-number">
                                            <span class="number">{{ countdown.days }}</span>
                                            <span class="countdown-infos-names">{{$i18n.t('home.days')}}</span>
                                        </div>
                                    </div>

                                    <div class="col-3 countdown-infos">
                                        <div class="countdown-number">
                                            <span class="number">{{ countdown.hours }}</span>
                                            <span class="countdown-infos-names">{{$i18n.t('home.hours')}}</span>
                                        </div>
                                    </div>

                                    <div class="col-3 countdown-infos">
                                        <div class="countdown-number">
                                            <span class="number">{{ countdown.minutes }}</span>
                                            <span class="countdown-infos-names">{{$i18n.t('home.minutes')}}</span>
                                        </div>
                                    </div>

                                    <div class="col-3 countdown-infos">
                                        <div class="countdown-number">
                                            <span class="number">{{ countdown.seconds }}</span>
                                            <span class="countdown-infos-names">{{$i18n.t('home.seconds')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalStart" style="position:absolute;left:210px;">
                                    <h2 class="col-12" v-text="splash.title"></h2>
                                </div>
                                <video v-if="!/\.(jpe?g|png|svg|gif)$/i.test(splash.url)" :src="splash.url" class="video-swiper" loop>
                                    <source :src="splash.url">
                                </video>
                                <div class="alignVerticalStart" v-else :style="'height:97%;width: 100%;align-self: baseline; background-image: url('+splash.url+');background-size:cover;background-repeat:no-repeat;background-position:center center'"></div>
                                <!-- <img v-else :src="splash.url" alt="slide" class="image-swiper"> -->
                                <div class="col-4 alignVerticalStart alignSplashButton">
                                    <button class="buttonSplash" v-if="!checkIOS && checkSafari && splash.link_safari != ''" v-on:click="openSplashLink(splash.link_type_safari, splash.link_safari)">
                                        {{ $t('home.splashButton') }}
                                    </button>
                                    <button class="buttonSplash" v-else-if="(checkMobile || checkIOS) && splash.link_mobile != ''" v-on:click="openSplashLink(splash.link_type_mobile, splash.link_mobile)">
                                        {{ $t('home.splashButton') }}
                                    </button>
                                    <button class="buttonSplash" v-else-if="splash.link != ''" v-on:click="openSplashLink(splash.link_type, splash.link)">
                                        {{ $t('home.splashButton') }}
                                    </button>
                                </div>
                                <input name="time" v-if="checkSafari && !checkIOS" hidden :value="splash.time_safari">
                                <input name="time" v-else-if="(checkMobile || checkIOS)" hidden :value="splash.time_mobile">
                                <input name="time" v-else hidden :value="splash.time">
                            
                            <!-- <img :src="spotlight.image"> -->
                            
                        </div>
                    </div>
                    <div class="swiper-load">
                        <div></div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div v-if="enableSchedule && agendaGroups.length > 0" class="master-calendar-container">
                <div v-if="agendaGroups.length > 0" class="home__noticias--agenda container-agenda-mobile">
                    <div class="swiper-container home__noticias--agenda---swiper">
                        <h1 class="title-schedule">{{ $t('home.agenda') }}</h1>
                        <div class="swiper-wrapper">
                            <div :key="index" v-for="(agendaList, index) in agendaGroups" class="calendar-event-container swiper-slide" >
                                <div :key="keyAgenda" v-for="(agenda, keyAgenda) in agendaList" class="box flex container-schedule-mobile" >
                                    <section class="mobile">
                                        <div class="alignVertical container-event-date">
                                            <div>
                                                <p class="date-calendar">{{ agenda.date }}</p>
                                                <p class="date-calendar especification">
                                                    {{ agenda.hour }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="content-line"/>
                                        <div class="container-event-description">
                                            <div>
                                                <p class="date-calendar">{{ agenda.title }}</p>
                                                <p
                                                    class="date-calendar especification"
                                                    v-html="agenda.description.replaceAll('\n', '<br>')"
                                                ></p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
                
            </div>
            <div class="container-footer" v-if="social_medias.length>0">
                <footer class="container-footer-content" v-for="(sm, index) in social_medias" :key="index">
                    <a :href="sm.url" target="_blank">
                        <img :src="sm.logo" style="width:42px; height:42px; border-radius:100px;" />
                    </a>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import axios from "axios";
import {mapGetters, mapActions} from "vuex";

export default {
    name: "Home",
    computed: {
        total_splashs() {
            var _root = this;
            return this.splashs.filter((splash) => {
                if ((splash.splash_type_safari == 'external' || splash.splash_type_safari == 'both') && _root.checkSafari && !_root.checkIOS && splash.show_safari) {
                    return splash
                } else if ((splash.splash_type_mobile == 'external' || splash.splash_type_mobile == 'both') && (_root.checkIOS || _root.checkMobile) && splash.show_mobile) {
                    return splash
                } else if ((splash.splash_type == 'external' || splash.splash_type == 'both') && !_root.checkSafari && !_root.checkMobile && !_root.checkIOS && splash.show_desktop) {
                    return splash
                }
                return false;
            });
        },
        ...mapGetters(["webview", "switch","design","checkMobile", "checkIOS", "checkSafari", "reward_slug"]),
        ...mapGetters({
          template_html_loaded: 'TemplateHTML/getHTMLTemplateLoaded'
        }),
    },
    data() {
        return {
            agendaGroups: [],
            sponsorsGroup: [],
            splashs: [],
            swiper_banner: null,
            swiper_agenda: null,
            swiper_swiper: null,
            social_medias: [],
            time: 0,
            interval: null,
            isActive: false,
            ref: null,
            video: null,
            enableSchedule: 0,
            countdownEnable: 0,
            countdownDate: null,
            countdownTitle: null,
            countdownImage: null,
            countdown: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            },
            countdownInterval: null,
        };
    },
    methods: {
        ...mapActions([
            "update_logged",
            "update_token",
            "update_email_verified",
            "update_id",
            "update_login_social",
            "update_switch",
            "update_reward_slug"
        ]),

        adjustBodyMargin() {
            // Obter o elemento body
            const body = document.body;

            // Obter o estilo computado do body
            const computedStyle = window.getComputedStyle(body);

            // Obter os valores de margem
            const marginTop = parseFloat(computedStyle.marginTop);
            const marginRight = parseFloat(computedStyle.marginRight);
            const marginBottom = parseFloat(computedStyle.marginBottom);
            const marginLeft = parseFloat(computedStyle.marginLeft);

            // Verificar se alguma margem é maior que 0 e ajustar para 0
            if (marginTop > 0 || marginRight > 0 || marginBottom > 0 || marginLeft > 0) {
                body.style.marginTop = '0px';
                body.style.marginRight = '0px';
                body.style.marginBottom = '0px';
                body.style.marginLeft = '0px';
                console.log('Margin adjusted to 0px');
            } else {
                console.log('No adjustment needed');
            }

            // Exibir os valores ajustados no console
            console.log(`Margin Top: ${body.style.marginTop}`);
            console.log(`Margin Right: ${body.style.marginRight}`);
            console.log(`Margin Bottom: ${body.style.marginBottom}`);
            console.log(`Margin Left: ${body.style.marginLeft}`);
        },

        openRegisterModal(){
            if(this.switch.login_signup) {
                this.$app.$refs.SignupModal.openModal(true);
            } else {
                return;
            }
        },

        //----- signup -----//
        block(v) {
            this.$app.wait(v);
            this.btn_submit_value = v ? this.$i18n.t('home.aguarde') : this.$i18n.t('home.cadastrar');
            this.btn_login_value = v ? this.$i18n.t('home.aguarde') : this.$i18n.t('home.login');
            this.$refs["btn-submit"].style.pointerEvents = v ? "none" : "auto";
            this.$refs["btn-submit"].style.opacity = v ? ".4" : "1";
        },
        termsHandler(e) {
            if (e.currentTarget.checked) this.isActive = true;
            else this.isActive = false;
        },
        loginSocial(data) {
            //type, origin,id, email, name
            if (
                String(data.email) == "" ||
                String(data.email).toLowerCase() == "undefined" ||
                String(data.email).toLowerCase() == "null"
            ) {
                this.$app.modal(
                    "error",
                    this.$i18n.t('home.erro'),
                    this.$i18n.t('home.EObrigatorio'),
                    null,
                    null,
                    null,
                    "OK"
                );
            } else {
                if (!this.submit) {
                    this.block(true);
                    let form_data = new FormData();
                    form_data.append("type", data.type);
                    form_data.append("origin", data.origin);
                    form_data.append("id", data.id);
                    form_data.append("email", data.email);                    
                    form_data.append('friend_code', this.ref);
                    axios.post(this.$url_api + 'api/loginSocial', form_data, {headers: {'language': this.$i18n.locale,}}).then(response => this.signupResponse(response, true, data.type, this.ref)).catch(error => this.signupError(error.response));
                }
            }
        },

        openSplashLink(link_type, url){
            // let url = obj.link;
            // if ((this.checkIOS || this.checkMobile) && obj.link_mobile != '') {
            //     url = obj.link_mobile;
            // } else if (this.checkSafari && obj.link_safari != '') {
            //     url = obj.link_safari
            // }

            if (url) {
                switch (link_type) {
                    case"lightbox":
                        this.$app.modalI("", url, null, "OK");
                        break;
                    case"self":
                        window.open(url, '_top')
                        break;
                    case"blank":
                        window.open(url, "_blank")
                        break;
                }
            }else{
                if(this.$app.logged || this.$app.isGuest) {
                    this.$router.push({path: '/map'})
                } else if(this.$app.switch.login_signup) {
                    this.$app.$refs.SignupModal.openModal(true);
                } else {
                    return;
                }
            }
        },

        getSetupResponse(data) {
            console.log('home getSetupResponse => ', data);
            let dataResponse = data.data;
            const root = this
            if (dataResponse){
                // console.log('LMS_ID HOME', dataResponse.lms_id);
                // root.$app.LMSHandler(dataResponse.lms_id, 'home', '', '');
                root.social_medias = [];
                root.splashs = [];
                root.agendaGroups = [];
                root.sponsorsGroup = [];
                root.splashs = dataResponse.splashs;
                root.social_medias = dataResponse.social_medias;
                root.enableSchedule = dataResponse.enable_schedule;
                root.countdownEnable = dataResponse.countdown_event.enable_countdown;
                root.countdownDate = dataResponse.countdown_event.countdown_date;
                root.countdownImage = dataResponse.countdown_event.countdown_image;
                root.countdownTitle = dataResponse.countdown_event.countdown_title;

                let i = 0;
                let count = 0;
                dataResponse.sponsors.forEach((value) => {
                    if (i >= 2) return;
                    if (root.sponsorsGroup[i] === undefined) {
                        root.sponsorsGroup[i] = [];
                    }
                    root.sponsorsGroup[i][count] = value;
                    count++;
                    if (count == 5) {
                        i++;
                        count = 0;
                    }
                });
                i = 0;
                count = 0;
                dataResponse.agendas.forEach((value) => {
                    if (root.agendaGroups[i] === undefined) {
                        root.agendaGroups[i] = [];
                    }
                    root.agendaGroups[i][count] = value;
                    count++;
                    if (window.innerWidth <= 600) {
                        if (count == 3) {
                            i++;
                            count = 0;
                        }
                    } else {
                        if (count == 4) {
                            i++;
                            count = 0;
                        }
                    }
                });
            }
        },
        //----- \signup\ -----//

        uniqueUserCookie(){
            if (!this.$app.getCookie("uniqueUser_metaverso"))
            {
                let root = this;
                let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale};

                axios.get(this.$url_api + 'api/registerHomeVisitor', {header: headers})
                .then(() => {
                    root.$app.setCookie('uniqueUser_metaverso', Math.random(), 7); // 7 days
                });
            }

        },
        backgroundFooter(url) {
            return url
                ? `background: url('${url}') center center / contain no-repeat`
                : "background-color: #ffffff";
        },
        onResize(){
            if (!this.$refs["home"])return;
            if(window.innerWidth>1000  && this.$app.$refs['copyright_footer']) {
                this.$refs["home"].style.height = `calc(100vh  - ${this.$app.$refs['copyright_footer'].clientHeight}px)`;
                this.$app.$refs["copyright_footer"].style.position = `fixed`;
            }else {
                this.$refs["home"].style.height = `100vh`;
            }
        }, 
        createAgendaSwiper(){
            this.swiper_agenda = new Swiper(".home__noticias--agenda---swiper", {
                spaceBetween: 30,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        },
        updateSlide(swiper) {
            const time = swiper.slides[swiper.activeIndex].querySelector("[name=time]").value*1000;
            const load = document.querySelector('.swiper-load div');
            if (this.video)
                this.video.pause();

            this.video = swiper.slides[swiper.activeIndex].querySelector("video");
            if (this.video)
                this.video.play();

            let count = 0;
            clearInterval(this.interval);
            clearTimeout(this.timeout);
            this.interval = setInterval(()=>{
                count+=100;
                load.style.width = `${(count *100 /time)}%`;
            },100);
            this.timeout = setTimeout(()=>{
                if (swiper.activeIndex<swiper.slides.length-1) {
                    swiper.slideNext();
                }else{
                    swiper.slideToLoop(0)
                }
            },time);
        },
        updateCountdown() {
            if(this.countdownDate) {
              const currentDate = new Date();
              const replaceApiDate = this.countdownDate.replace(' ', 'T');
              const apiDate = new Date(replaceApiDate);
              const timeDifference = apiDate - currentDate;

              if (timeDifference > 0) {
                this.countdown.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                this.countdown.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.countdown.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                this.countdown.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
              } else {
                clearInterval(this.countdownInterval);
                this.countdownEnable = 0;
              }
            }
        },
    },
    mounted() {
        if((this.$app.logged || this.$app.isGuest) && this.switch.without_landing_page == 'without_landing_page') {
            this.$router.push({path: '/map'})
        }
        
        this.uniqueUserCookie();

        this.$app.analytics("home");

        this.ref = this.$route.query.ref ? this.$route.query.ref : "";
        let event_name = this.switch.Nome_Aba ? this.switch.Nome_Aba : localStorage.getItem("eventmap") ? localStorage.getItem("eventmap") : "";
        axios.get(this.$url_api + "api/setupHome", {headers: {'language': this.$i18n.locale,}, params:{event_name}})
            .then((response) => this.getSetupResponse(response));
            
        var root = this;
        this.swiper_banner = new Swiper(".home__banner", {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            observer: true,
            on: {
                observerUpdate: function (swiper) {
                    root.updateSlide(swiper)
                },
                slideChange: function (swiper) {
                    root.updateSlide(swiper)
                },
            },
        });
        this.createAgendaSwiper();

        this.$nextTick(() => {
            this.onResize()
            window.addEventListener('resize', this.onResize);
        })
        let uri_qrcode = this.$route.fullPath.split('?')[1]; 
        let params_qrcode = new URLSearchParams(uri_qrcode);
        if (params_qrcode.get('award')) {
            this.update_reward_slug(params_qrcode.get('award'))
            if (this.logged || this.isGuest) {
                this.getRewards()
            } else {
                this.$app.$refs["LoginModal"].openModal(true)
            }
        }
        console.log('award no home', this.reward_slug)
        console.log('redirect  path no home', localStorage.getItem('redirectPath'))

        this.adjustBodyMargin();
    },
    updated() {
        if(this.agendaGroups.length > 0 && this.swiper_agenda) {
            let valid = false;
            try {
                valid = this.swiper_agenda.$el.tagName == 'div';
            } catch (e) {
                valid = false;
            }
            if (!valid){
                this.createAgendaSwiper();
            }
            this.swiper_agenda.update();
        }
        if(this.spotlightsBanner && this.spotlightsBanner.length > 0 && this.swiper_banner){
            this.swiper_banner.update();
        }

        this.updateCountdown();
        this.countdownInterval = setInterval(this.updateCountdown, 1000);
    },
    beforeDestroy() {
        // this.$zendesk.hide();
        // clearInterval(this.interval);
        //this.$refs['video'].src = null;
        //this.$refs['video-container'].innerHTML = '';
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        window.removeEventListener('resize', this.onResize);
        clearInterval(this.countdownInterval);
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: "url",
                    content: this.$url,
                },
                {
                    name: "title",
                    content: this.$i18n.t('home.finaisAoVivo'),
                },
                {
                    name: "description",
                    content: this.$i18n.t('home.assistaAosJogos'),
                },
                {
                    name: "image",
                    content: this.$url + "assets/images/metas/home.png",
                },
                {
                    property: "og:url",
                    content: this.$url,
                },
                {
                    property: "og:title",
                    content: this.$i18n.t('home.finaisAoVivo'),
                },
                {
                    property: "og:description",
                    content: this.$i18n.t('home.assistaAosJogos'),
                },
                {
                    property: "og:image",
                    content: this.$url + "assets/images/metas/home.png",
                },
                {
                    property: "twitter:url",
                    content: this.$url,
                },
                {
                    property: "twitter:title",
                    content: this.$i18n.t('home.finaisAoVivo'),
                },
                {
                    property: "twitter:description",
                    content: this.$i18n.t('home.assistaAosJogos'),
                },
                {
                    property: "twitter:image",
                    content: this.$url + "assets/images/metas/home.png",
                },
            ],
        };
    },
};
</script>

<style lang="scss">
    .alignSplashButton {
        justify-content:end;
        position:absolute;
        left: -140px;
        bottom: 260px;
    }

    @media screen and (max-width: 950px) {
        .alignSplashButton {
            left: 10%;
            bottom: 30%;
        }
    }

    @media screen and (max-width: 768px) {
        .alignSplashButton {
            left: 15%;
            bottom: 30%;
        }
    }

    @media screen and (max-width: 499px) {
        .alignSplashButton {
            left: 200px;
            bottom: 30%;
        }
    }
</style>